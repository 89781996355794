import React, { lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import { setAccessToken } from './request';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Auth/Login'));
const CreateAccount = lazy(() => import('./pages/Auth/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import { getUserProfile, loginUser } from './store/user.action';
import Invite from './pages/Invite';

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <AccessibleNavigationAnnouncer />
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/create-account" component={CreateAccount} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route
                path="/auth/callback"
                exact
                component={() => {
                  const dispatch = useDispatch();
                  const url = window.location.href;
                  const params = new URLSearchParams(url.split('#')[1]);

                  if (!params.has('access_token')) return <Redirect to="/login" />;
                  const access_token = params.get('access_token');
                  const refresh_token = params.get('refresh_token');

                  setAccessToken(access_token + ' ' + refresh_token);
                  localStorage.setItem('access_token', access_token);
                  localStorage.setItem('refresh_token', refresh_token);

                  dispatch(getUserProfile());
                  dispatch(loginUser());

                  // if invitation_token present in local storage, remove it              // if invitation_token present in local storage, remove it
                  const invitationToken = localStorage.getItem('invitation_token');

                  if (invitationToken) {
                    localStorage.removeItem('invitation_token');

                    return <Redirect to={`/app/accept-invite?token=${invitationToken}`} />;
                  }

                  return <Redirect to="/app/dashboard" />;
                }}
              />
              <Route path="/app/accept-invite" component={Invite} />
              {/* Place new routes over this */}
              <Route path="/app" component={Layout} />
              {/* Any other pages should go to 404 */}
              <Route component={Login} />

              {/* If you have an index page, you can remothis Redirect */}
              <Redirect exact from="/" to="/login" />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
