import './assets/css/tailwind.output.css';
import './assets/css/common.css';

import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import ThemedSuspense from './components/ThemedSuspense';
import { SidebarProvider } from './context/SidebarContext';
import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

const root = createRoot(document.getElementById('root'));

root.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <ConfigProvider
        theme={{
          token: {
            // fontFamily: 'Quando',
          },
          components: {
            Tabs: {
              inkBarColor: '#1890ff',
            },
          },
        }}
        componentSize="middle"
      >
        <App />
      </ConfigProvider>
    </Suspense>
  </SidebarProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
