import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  logged: false,
  email: '',
  first_name: '',
  last_name: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserItem(state, action) {
      return action.payload;
    },
    setUserLogged(state, action) {
      state.logged = action.payload;
    },
    setUserLoggedOut(state) {
      state.logged = false;
    },
  },
});

export const { setUserItem, setLastSelectedCompanyId } = userSlice.actions;

export default userSlice.reducer;
