import { Spin, Typography } from 'antd';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import Loader from './Loader';

const { Text } = Typography;

function ThemedSuspense() {
  return (
    <div className="w-full min-h-screen center flex flex-1 justify-center">
      <div className="self-center flex flex-col">
        <Spin size="large" indicator={<Loader />} />
        <Text className="text-center font-medium mt-4">Loading...</Text>
      </div>
    </div>
  );
}

export default ThemedSuspense;
