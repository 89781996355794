import axios from 'axios';
import { API_URL } from './constants';
import { notification } from 'antd';

const request = axios.create({
  baseURL: API_URL,
  // timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

request.interceptors.request.use(
  config => {
    const token =
      localStorage.getItem('access_token') + ' ' + localStorage.getItem('refresh_token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

// add interceptor to check of any 401 responses from the server
request.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      // handle 401 errors
      console.log('401 error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      notification.error({
        message: 'Session Expired',
        description: 'Your session has expired. Please login again.',
      });
      setTimeout(() => {
        window.location.href = '/login?expired=true';
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export const setAccessToken = (token = '') => {
  if (token) {
    request.defaults.headers.common['Authorization'] = token;
  } else {
    delete request.defaults.headers.common['Authorization'];
  }
};

export default request;
