import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

function Loader() {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />;
}

export const GlobalLoader = ({ show }) => {
  if (!show) return null;
  return (
    <div className="fixed z-10 inset-0 bg-gray-50 bg-opacity-5 flex justify-center items-center">
      <Loader />
    </div>
  );
};

export default Loader;
